import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import Partners from '../components/sections/partners'

import womanPhone from '../images/thank-you-woman-phone.png'

const ThankYouAlt = ({ location }) => {
  React.useEffect(() => {
    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'thank',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Layout phone="866-740-2901" location={location}>
      <SEO
        title="Thank You!"
        keywords={[
          `medicare`,
          `health`,
          `insurance`,
          `plans`,
          `affordable`,
          `supplement`,
        ]}
      />
      <Header phone="866-740-2901" hideCta className="centered" />
      <Intro
        classNames="title-content-bg"
        colType="full-col alt"
        title="Thank you! Your quote is ready!"
        subTitle="Thank you for taking the time to answer a few questions for us. Call now to speak with a licensed agent about your customized quotes."
        phone="866-740-2901"
        showCtaButton
      >
        <div className="img">
          <img src={womanPhone} alt="Thank you!" />
        </div>
      </Intro>
      <main id="main">
        <Partners />
      </main>
    </Layout>
  )
}

export default ThankYouAlt
